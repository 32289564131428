import Axios from 'axios'

import config from 'config'
import { getAccessToken } from './accessToken'

export const baseURL = config.workbaseUrl

const axios = Axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

axios.interceptors.request.use(
  async (configs) => {
    const accessToken = getAccessToken()

    const updatedConfigs = {
      ...configs,
      headers: {
        ...configs.headers,
        Authorization: `Bearer ${accessToken}`,
        'Ocp-Apim-Subscription-Key': config.apimKey,
      },
    }

    return updatedConfigs
  },
  (error) => {
    // Do something with request error
    Promise.reject(error)
  }
)

const baseConfigs = {
  method: 'get',
}

const api = (url, options = {}, configs = {}) => {
  const mergedConfigs = { ...baseConfigs, ...configs }

  return axios({
    ...mergedConfigs,
    params: options,
    url,
  })
}

export default api
