import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

// material-ui
import { styled, useTheme } from '@mui/material/styles'
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material'

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs'
import Header from './Header'
import Sidebar from './Sidebar'
import { drawerWidth, smallDrawerWidth } from 'store/constant'
import { SET_MENU } from 'store/actions'

// assets
import { IconChevronRight } from '@tabler/icons'

import useCurrentUser from 'hooks/useCurrentUser'
import useReduxAction from 'hooks/useReduxAction'
import { getMenuItems } from 'utils/utilities'

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      width: `calc(100% - ${smallDrawerWidth}px)`,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${smallDrawerWidth}px)`,
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${smallDrawerWidth}px)`,
      padding: '16px',
      marginRight: '10px',
    },
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
    },
  }),
}))

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened)
  const dispatch = useDispatch()
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
  }

  useReduxAction('enums', 'loadEnums', 'MerchandiseGroup', [])
  useReduxAction('enums', 'loadEnums', 'PartSubType', [])
  useReduxAction('enums', 'loadEnums', 'PartType', [])
  useReduxAction('enums', 'loadEnums', 'ServiceType', [])
  useReduxAction('enums', 'loadEnums', 'UserRole', [])
  // unique enums
  useReduxAction('enums', 'loadEnums', 'PartFlag', [])
  useReduxAction('enums', 'loadEnums', { version: 3 }, [], {
    dispatchAction: (action, requestOptions) => action('ProviderScheduleManagementStatus', requestOptions),
  })
  useReduxAction('enums', 'loadEnums', { version: 3 }, [], {
    dispatchAction: (action, requestOptions) => action('ProviderScheduleProviderStatus', requestOptions),
  })
  useReduxAction('enums', 'loadEnums', 'State', [])
  useReduxAction('enums', 'loadEnums', 'TaskOwnerType', [])

  const { currentUser } = useCurrentUser()

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
      <Main theme={theme} open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs separator={IconChevronRight} navigation={getMenuItems(currentUser)} icon title rightAlign />
        <Outlet />
      </Main>
    </Box>
  )
}

export default MainLayout
