import { normalize } from 'normalizr'
import { Schemas } from 'redux/schema'
import { updateEntities, deleteEntity } from 'redux/entities'

import api from 'utils/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/product/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/product/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/product/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/product/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/product/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/product/CREATE_FAILURE'

const UPDATE_REQUEST = 'contenthouse/product/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/product/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/product/UPDATE_FAILURE'

const DELETE_REQUEST = 'contenthouse/product/DELETE_REQUEST'
const DELETE_SUCCESS = 'contenthouse/product/DELETE_SUCCESS'
const DELETE_FAILURE = 'contenthouse/product/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loading: false,
  result: {},
  updating: false,
}

// Actions
export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}) {
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadProducts(options) {
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api('/products', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function createProduct(product, options = {}) {
  // ToDo: replace with a Utility function
  const clean = {}
  Object.keys(product).forEach((k) => product[k] && (clean[k] = product[k]))

  const config = {
    method: 'POST',
    data: clean,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = api(`/products`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT)
        dispatch(updateEntities(normalizedJson))
        dispatch(createSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function updateProduct(product, options = {}) {
  // ToDo: replace with a Utility function
  const clean = {}
  Object.keys(product).forEach((k) => product[k] && (clean[k] = product[k]))

  const config = {
    method: 'PUT',
    data: clean,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = api(`/products/${product.id}`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT)

        const updateEntityOptions = {
          mergeOptions: {
            customMerge: () => (_, source) => source,
          },
        }

        dispatch(updateEntities(normalizedJson, updateEntityOptions))
        dispatch(updateSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteProduct(product) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = api(`/products/${product.id}`, { permanent: true }, config)
      .then(() => {
        const normalizedJson = normalize(product, Schemas.PRODUCT)
        dispatch(deleteEntity(normalizedJson))
        dispatch(deleteSuccess())

        return { success: true, result: product }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload, errors } = action

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors,
      }
    default:
      return state
  }
}
