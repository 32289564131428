import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'

// defaultTheme
import themes from 'themes'

// project imports
import NavigationScroll from 'layout/NavigationScroll'

// auth
import AuthenticationController from 'auth/AuthController'

// ==============================|| PAGES ||============================== //
const Pages = () => {
  const customization = useSelector((state) => state.customization)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <AuthenticationController>
          <CssBaseline />
          <NavigationScroll />
        </AuthenticationController>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

// ==============================|| APP ||============================== //
const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <Pages />
      </MsalAuthenticationTemplate>
    </MsalProvider>
  )
}

App.propTypes = {
  instance: PropTypes.object,
}

export default App
