import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'

import config from 'config'

const cms1 = {
  id: 'cms1',
  title: 'CMS 1',
  type: 'group',
  children: [
    {
      id: 'clients',
      title: 'Clients',
      type: 'item',
      url: `${config.cms1Url}/systemManager/clients`,
      icon: Inventory2OutlinedIcon,
      breadcrumbs: false,
      external: true,
      target: true,
    },
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      url: `${config.cms1Url}/systemManager/users`,
      icon: PersonOutlinedIcon,
      breadcrumbs: false,
      external: true,
      target: true,
    },
    {
      id: 'youtube',
      title: 'Youtube',
      type: 'item',
      url: `${config.cms1Url}/systemManager/youtube`,
      icon: VideocamOutlinedIcon,
      breadcrumbs: false,
      external: true,
      target: true,
    },
    {
      id: 'zones',
      title: 'Zones',
      type: 'item',
      url: `${config.cms1Url}/systemManager/zones`,
      icon: LanguageOutlinedIcon,
      breadcrumbs: false,
      external: true,
      target: true,
    },
  ],
}

export default cms1
