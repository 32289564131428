import apiV2 from 'utils/apiV2'

import { handleError } from '../utils'

const CREATE_REQUEST = 'contenthouse/swap/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/swap/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/swap/CREATE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  errors: [],
  loaded: false,
  loading: false,
  result: {},
}

// Actions
export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess(payload = {}) {
  return {
    type: CREATE_SUCCESS,
    payload,
  }
}

export function createFailure(errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

// Action Creators
export function swapProviders(swapParams, options = {}) {
  const config = {
    method: 'POST',
    data: swapParams,
  }

  return (dispatch) => {
    dispatch(createRequest())

    const promise = apiV2('jobs/tasks/swap', options, config)
      .then(({ data }) => {
        dispatch(createSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(createFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload, errors } = action

  switch (type) {
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
        result: payload,
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors,
      }
    default:
      return state
  }
}
