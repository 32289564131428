/* eslint-disable no-process-env */

const apimUrl = 'https://apim-ch-api.azure-api.net'

const workbaseUrls = {
  production: `${apimUrl}/workbase`,
  sandbox: `${apimUrl}/workbase;rev=1`,
  staging: `${apimUrl}/workbase;rev=2`,
}

const meUrls = {
  production: `${apimUrl}/workbase/me`,
  sandbox: `${apimUrl}/workbase/me;rev=1`,
  staging: `${apimUrl}/workbase/me;rev=2`,
}

const clientUrls = {
  production: 'https://client.contenthouse.io',
  sandbox: 'https://staging-client.contenthouse.io',
  staging: 'https://staging-client.contenthouse.io',
}

const cms1Urls = {
  production: 'https://cms.contenthouse.io',
  sandbox: 'https://staging-cms.contenthouse.io',
  staging: 'https://staging-cms.contenthouse.io',
}

const workbaseV2Urls = {
  production: 'https://func-ch-backend-workbase.azurewebsites.net/api',
  sandbox: 'https://func-ch-staging-backend-workbase.azurewebsites.net/api',
  staging: 'https://func-ch-staging-backend-workbase.azurewebsites.net/api',
}

const defaultProjectParts = {
  production: '2, 28, 29, 30, 31, 32, 33',
  sandbox: '52, 189, 190, 191, 192, 193, 194',
  staging: '52, 189, 190, 191, 192, 193, 194',
}

const config = {
  // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
  // like '/cms2-contenthouse/react/default'
  aadb2cApiEndpoint: `${workbaseUrls[process.env.REACT_APP_APPLICATION_ENV]}/users`,
  aadb2cApiScopes: process.env.REACT_APP_AADB2C_API_SCOPES,
  aadb2cAuthorityDomain: process.env.REACT_APP_AADB2C_AUTHORITY_DOMAIN,
  aadb2cClientId: process.env.REACT_APP_AADB2C_CLIENT_ID,
  aadb2cUserFlowAgent: process.env.REACT_APP_AADB2C_USER_FLOW_AGENT,
  aadb2cUserFlowAgentAuthority: process.env.REACT_APP_AADB2C_USER_FLOW_AGENT_AUTHORITY,
  apimKey: 'd1c17ce8091944429f557ae46c3031e8',
  basename: '/',
  borderRadius: 12,
  clientUrl: clientUrls[process.env.REACT_APP_APPLICATION_ENV],
  cms1Url: cms1Urls[process.env.REACT_APP_APPLICATION_ENV],
  defaultPath: '/',
  defaultProjectParts: defaultProjectParts[process.env.REACT_APP_APPLICATION_ENV],
  fontFamily: `'Roboto', sans-serif`,
  googleComponentRestrictions: { country: 'au' },
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  meUrl: meUrls[process.env.REACT_APP_APPLICATION_ENV],
  travelTime: 45,
  workbaseUrl: workbaseUrls[process.env.REACT_APP_APPLICATION_ENV],
  workbaseV2Url: workbaseV2Urls[process.env.REACT_APP_APPLICATION_ENV],
}

export default config
