// material-ui
import { Typography } from '@mui/material'

// project imports
import NavGroup from './NavGroup'

import useCurrentUser from 'hooks/useCurrentUser'
import { getMenuItems } from 'utils/utilities'

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { currentUser } = useCurrentUser()

  const navItems = getMenuItems(currentUser)?.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  return <>{navItems}</>
}

export default MenuList
