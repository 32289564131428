import React from 'react'
import PropTypes from 'prop-types'

import { useMsal } from '@azure/msal-react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Stack, Typography, Link } from '@mui/material'

// project imports
import logo from 'assets/icons/contenthouse.svg'

// ==============================|| ERROR PAGE ||============================== //

const PlaceholderPage = (props) => {
  const { message } = props
  const theme = useTheme()
  const { instance } = useMsal()

  const handleLogout = async () => {
    console.log('Logout')
    instance.logoutRedirect()
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        minHeight: '90vh',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Stack spacing={5} width={['95%', '400px']} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <img src={logo} alt="Loading..." width={50} height={50} />
        <Typography variant="h2" sx={{ color: theme.palette.grey[500] }}>
          {message}
        </Typography>
        <Link onClick={handleLogout} variant="body2" underline="hover" color="primary">
          Logout
        </Link>
      </Stack>
    </Box>
  )
}

PlaceholderPage.propTypes = {
  message: PropTypes.string,
}

export default PlaceholderPage
