/* eslint-disable max-len */
// material-ui
import { useTheme } from '@mui/material/styles'

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  const theme = useTheme()

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={logo} alt="Berry" width="100" />
     *
     */
    <svg width="165" height="36" viewBox="0 0 280 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9203 4.18784C26.185 2.60406 22.815 2.60405 20.0797 4.18784L4.42034 13.2548C1.68502 14.8386 0 17.7655 0 20.9331V39.0669C0 42.2345 1.68502 45.1614 4.42034 46.7452L20.0797 55.8122C22.815 57.3959 26.185 57.3959 28.9203 55.8122L44.5797 46.7452C47.315 45.1614 49 42.2345 49 39.0669V20.9331C49 17.7655 47.315 14.8386 44.5797 13.2548L28.9203 4.18784ZM29.4088 35.94C29.911 35.94 30.3181 36.3408 30.3181 36.8353V39.7071C30.3181 40.2016 29.911 40.6024 29.4088 40.6024H14.712C13.7703 40.6024 13.007 39.8508 13.007 38.9237V36.8353C13.007 36.3408 13.4141 35.94 13.9163 35.94H29.4088ZM33.1129 25.2308C32.5067 24.8829 31.7456 25.3134 31.7456 26.0043V39.7446C31.7456 40.2391 32.1528 40.6399 32.655 40.6399H35.1269C36.0685 40.6399 36.8319 39.8884 36.8319 38.9613V27.8797C36.8319 27.5612 36.66 27.2667 36.3805 27.1063L33.1129 25.2308ZM17.1839 34.4671C17.6861 34.4671 18.0932 34.0662 18.0932 33.5718V25.9643C18.0932 25.2748 17.335 24.8441 16.7287 25.1892L13.4611 27.0494C13.1801 27.2094 13.007 27.5048 13.007 27.8245V33.5718C13.007 34.0662 13.4141 34.4671 13.9163 34.4671H17.1839ZM13.8401 20.4027C13.3238 20.7054 13.0075 21.2534 13.0075 21.845V24.1529C13.0075 24.8413 13.7636 25.2721 14.3699 24.9291L24.4878 19.2045C24.7691 19.0453 25.1152 19.0458 25.3961 19.2057L35.5127 24.9649C36.1189 25.31 36.8772 24.8793 36.8772 24.1899V21.8859C36.8772 21.2959 36.5626 20.7493 36.0486 20.446L25.4083 14.1685C25.1213 13.9992 24.7631 13.9987 24.4756 14.1673L13.8401 20.4027Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M64 30.509C64 34.306 66.6538 36.9638 70.4164 36.9638C73.452 36.9638 75.7423 35.2823 76.4693 32.5341H73.0521C72.525 33.6189 71.5616 34.1613 70.3801 34.1613C68.4715 34.1613 67.1628 32.751 67.1628 30.509C67.1628 28.267 68.4715 26.8567 70.3801 26.8567C71.5616 26.8567 72.525 27.3992 73.0521 28.484H76.4693C75.7423 25.7357 73.452 24.0362 70.4164 24.0362C66.6538 24.0362 64 26.7121 64 30.509Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M96.3861 30.491C96.3861 26.6759 93.496 24 89.8606 24C86.2434 24 83.2987 26.6759 83.2987 30.491C83.2987 34.306 86.2434 37 89.8606 37C93.4778 37 96.3861 34.306 96.3861 30.491ZM86.4797 30.491C86.4797 28.2309 87.7884 26.8025 89.8606 26.8025C91.8964 26.8025 93.2233 28.2309 93.2233 30.491C93.2233 32.7149 91.8964 34.1794 89.8606 34.1794C87.7884 34.1794 86.4797 32.7149 86.4797 30.491Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M111.875 24.1808V32.0459L106.677 24.1808H103.568V36.8734H106.677V29.0445L111.875 36.8734H114.983V24.1808H111.875Z"
        fill={theme.palette.primary.main}
      />
      <path d="M121.999 26.6579H125.38V36.8734H128.488V26.6579H131.869V24.1808H121.999V26.6579Z" fill={theme.palette.primary.main} />
      <path
        d="M146.698 24.1808H138.882V36.8734H146.698V34.3964H141.99V31.612H146.153V29.2253H141.99V26.6579H146.698V24.1808Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M162.476 24.1808V32.0459L157.277 24.1808H154.169V36.8734H157.277V29.0445L162.476 36.8734H165.584V24.1808H162.476Z"
        fill={theme.palette.primary.main}
      />
      <path d="M172.6 26.6579H175.98V36.8734H179.089V26.6579H182.47V24.1808H172.6V26.6579Z" fill={theme.palette.primary.main} />
      <path
        d="M206.731 36.8734H209.839V24.1808H206.731V29.153H201.896V24.1808H198.788V36.8734H201.896V31.6481H206.731V36.8734Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M230.096 30.491C230.096 26.6759 227.206 24 223.571 24C219.954 24 217.009 26.6759 217.009 30.491C217.009 34.306 219.954 37 223.571 37C227.188 37 230.096 34.306 230.096 30.491ZM220.19 30.491C220.19 28.2309 221.499 26.8025 223.571 26.8025C225.607 26.8025 226.934 28.2309 226.934 30.491C226.934 32.7149 225.607 34.1794 223.571 34.1794C221.499 34.1794 220.19 32.7149 220.19 30.491Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M237.224 31.7566C237.224 35.2643 239.533 37 242.495 37C245.44 37 247.912 35.2643 247.912 31.7566V24.1808H244.804V31.7747C244.804 33.2754 244.022 34.1433 242.55 34.1433C241.078 34.1433 240.332 33.2754 240.332 31.7747V24.1808H237.224V31.7566Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M264.818 33.0946C264.818 28.8456 258.511 29.8401 258.511 27.6342C258.511 26.8387 259.074 26.459 259.819 26.4771C260.656 26.4951 261.237 26.9833 261.292 27.7969H264.654C264.527 25.3741 262.655 24 259.874 24C257.202 24 255.221 25.338 255.221 27.7427C255.184 32.2448 261.564 30.9791 261.564 33.3296C261.564 34.0709 260.983 34.5229 260.074 34.5229C259.201 34.5229 258.583 34.0529 258.492 33.0765H255.184C255.275 35.644 257.42 37 260.165 37C263.146 37 264.818 35.2281 264.818 33.0946Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M280 24.1808H272.184V36.8734H280V34.3964H275.292V31.612H279.455V29.2253H275.292V26.6579H280V24.1808Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  )
}

export default Logo
