import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'
import ErrorElement from 'routes/ErrorElement'

// routing
const Dashboard = Loadable(lazy(() => import('views/Dashboard')))
const ProviderDiary = Loadable(lazy(() => import('views/ProviderDiary')))

// ==============================|| PROVIDER ROUTING ||============================== //

const ProviderRoutes = {
  path: '/',
  element: <MainLayout />,
  errorElement: <ErrorElement />,
  children: [
    {
      path: '/',
      element: <Dashboard />,
      errorElement: <ErrorElement />,
    },
    {
      path: 'provider-diary',
      element: <ProviderDiary />,
    },
  ],
}

export default ProviderRoutes
