import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined'
import StorefrontIcon from '@mui/icons-material/Storefront'

const management = {
  id: 'management',
  title: 'Management',
  collapsedTitle: 'Manage',
  type: 'group',
  children: [
    {
      id: 'bookings',
      title: 'Bookings',
      type: 'item',
      url: `/bookings`,
      icon: EventOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: 'workflows',
      title: 'Workflows',
      type: 'item',
      url: '/workflows',
      icon: AccountTreeOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: 'products',
      title: 'Products',
      type: 'item',
      url: '/products',
      icon: Inventory2OutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: 'parts',
      title: 'Parts',
      type: 'item',
      url: '/parts',
      icon: CategoryOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: 'prices',
      title: 'Prices',
      type: 'item',
      url: '/prices',
      icon: AttachMoneyOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: 'merchandise',
      title: 'Merchandise',
      collapsedTitle: 'Merch',
      type: 'item',
      url: '/merchandise',
      icon: StorefrontIcon,
      breadcrumbs: false,
    },
    {
      id: 'multiple-diary',
      title: 'Multiple Diary',
      collapsedTitle: 'Diary',
      type: 'item',
      url: '/multiple-diary',
      icon: RecentActorsOutlinedIcon,
      breadcrumbs: false,
    },
  ],
}

export default management
