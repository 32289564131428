import { combineReducers } from 'redux'

import { reducers } from './modules/index'
import customizationReducer from 'store/customizationReducer'

const createRootReducer = () =>
  combineReducers({
    ...reducers,
    customization: customizationReducer,
  })

export default createRootReducer
