import React from 'react'
import { useRouteError } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, Stack, Typography, Link } from '@mui/material'

// project imports
import logo from 'assets/icons/contenthouse.svg'

// ==============================|| ERROR PAGE ||============================== //

const ErrorElement = () => {
  const routeError = useRouteError()
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        minHeight: '90vh',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Stack spacing={5} width={['95%', '400px']} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <img src={logo} alt="Something went wrong." width={50} height={50} />
        <Typography variant="h2" sx={{ color: theme.palette.grey[500] }}>
          {routeError?.statusText || 'Something went wrong.'}
        </Typography>
        <Link href="/" variant="body2" underline="hover" color="primary">
          Back to Dashboard
        </Link>
      </Stack>
    </Box>
  )
}

export default ErrorElement
