import React, { useEffect, useState } from 'react'

import { getAccessToken } from 'utils/accessToken'

import AuthorizationController from 'auth/AuthorizationController'
// When already logged in the token is not instantly available
// We wait and check 0.1 second later for it to be available

const waitForUserInfo = (setToken) => {
  const intervalId = setInterval(() => {
    const token = getAccessToken()
    if (token) {
      clearInterval(intervalId)
      setToken(token)
    }
  }, 100)
}

const AuthenticationController = () => {
  const [token, setToken] = useState(null)

  useEffect(() => {
    waitForUserInfo(setToken)
  }, [])

  if (token) {
    return <AuthorizationController />
  }
}

export default AuthenticationController
