import { configureStore } from '@reduxjs/toolkit'

import createRootReducer from '../redux/rootReducer'
import lastModifiedMiddleware from '../redux/middleware/lastModified'

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
  reducer: createRootReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActionPaths: ['_options.mergeOptions.customMerge'],
      },
    }).concat(lastModifiedMiddleware),
})
const persister = 'Free'

export { store, persister }
