import { schema } from 'normalizr'

// Circular Dependencies - Use the define method
// const item = new schema.Entity('item')
// const child = new schema.Entity('child')
// item.define({ children: [child] })

// Define Entities
const holdSlot = new schema.Entity('holdSlots')
const merchandise = new schema.Entity('merchandises')
const office = new schema.Entity('offices')
const part = new schema.Entity('parts')
const price = new schema.Entity('prices')
const product = new schema.Entity('products')
const productPart = new schema.Entity('productParts')
const project = new schema.Entity('projects')
const schedule = new schema.Entity('schedules')
const taskSheet = new schema.Entity('taskSheets')
const user = new schema.Entity('users')
const workflow = new schema.Entity('workflows')
const zone = new schema.Entity('zones')

// enums
const partType = new schema.Entity(
  'partTypes',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

const partSubType = new schema.Entity(
  'partSubTypes',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

const serviceType = new schema.Entity(
  'serviceTypes',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

const merchandiseGroup = new schema.Entity(
  'merchandiseGroups',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

const userRole = new schema.Entity(
  'userRoles',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

// unique enums
const partFlag = new schema.Entity(
  'partFlags',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

const providerScheduleManagementStatus = new schema.Entity(
  'providerScheduleManagementStatuses',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

const providerScheduleProviderStatus = new schema.Entity(
  'providerScheduleProviderStatuses',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

const state = new schema.Entity(
  'states',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

const taskOwnerType = new schema.Entity(
  'taskOwnerTypes',
  {},
  {
    idAttribute: (value) => value.value,
  }
)

// Export Schemas
export const Schemas = {
  HOLD_SLOT: holdSlot,
  HOLD_SLOT_ARRAY: [holdSlot],
  MERCHANDISE: merchandise,
  MERCHANDISE_ARRAY: [merchandise],
  OFFICE: office,
  OFFICE_ARRAY: [office],
  PART: part,
  PART_ARRAY: [part],
  PRICE: price,
  PRICE_ARRAY: [price],
  PRODUCT: product,
  PRODUCT_ARRAY: [product],
  PRODUCT_PART: productPart,
  PRODUCT_PART_ARRAY: [productPart],
  PROJECT: project,
  PROJECT_ARRAY: [project],
  SCHEDULE: schedule,
  SCHEDULE_ARRAY: [schedule],
  TASK_SHEET: taskSheet,
  TASK_SHEET_ARRAY: [taskSheet],
  USER: user,
  USER_ARRAY: [user],
  WORKFLOW: workflow,
  WORKFLOW_ARRAY: [workflow],
  ZONE: zone,
  ZONE_ARRAY: [zone],
  // enums
  MERCHANDISE_GROUP: merchandiseGroup,
  MERCHANDISE_GROUP_ARRAY: [merchandiseGroup],
  PART_TYPE: partType,
  PART_TYPE_ARRAY: [partType],
  PART_SUB_TYPE: partSubType,
  PART_SUB_TYPE_ARRAY: [partSubType],
  SERVICE_TYPE: serviceType,
  SERVICE_TYPE_ARRAY: [serviceType],
  USER_ROLE: userRole,
  USER_ROLE_ARRAY: [userRole],
  // unique enums
  PART_FLAG: partFlag,
  PART_FLAG_ARRAY: [partFlag],
  PROVIDER_SCHEDULE_MANAGEMENT_STATUS: providerScheduleManagementStatus,
  PROVIDER_SCHEDULE_MANAGEMENT_STATUS_ARRAY: [providerScheduleManagementStatus],
  PROVIDER_SCHEDULE_PROVIDER_STATUS: providerScheduleProviderStatus,
  PROVIDER_SCHEDULE_PROVIDER_STATUS_ARRAY: [providerScheduleProviderStatus],
  STATE: state,
  STATE_ARRAY: [state],
  TASK_OWNER_TYPE: taskOwnerType,
  TASK_OWNER_TYPE_ARRAY: [taskOwnerType],
}

export default Schemas
