import { normalize } from 'normalizr'
import { Schemas } from 'redux/schema'
import { updateEntities, deleteEntity } from 'redux/entities'

import apiV2 from 'utils/apiV2'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/task/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/task/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/task/FETCH_FAILURE'

const CREATE_REQUEST = 'contenthouse/task/CREATE_REQUEST'
const CREATE_SUCCESS = 'contenthouse/task/CREATE_SUCCESS'
const CREATE_FAILURE = 'contenthouse/task/CREATE_FAILURE'

const UPDATE_REQUEST = 'contenthouse/task/UPDATE_REQUEST'
const UPDATE_SUCCESS = 'contenthouse/task/UPDATE_SUCCESS'
const UPDATE_FAILURE = 'contenthouse/task/UPDATE_FAILURE'

const DELETE_REQUEST = 'contenthouse/task/DELETE_REQUEST'
const DELETE_SUCCESS = 'contenthouse/task/DELETE_SUCCESS'
const DELETE_FAILURE = 'contenthouse/task/DELETE_FAILURE'

// Initial State
const initialState = {
  creating: false,
  deleting: false,
  errors: [],
  loaded: false,
  loadedForKeys: [],
  loading: false,
  result: {},
  updating: false,
}

// Actions
export function fetchRequest(payload = {}) {
  return {
    type: FETCH_REQUEST,
    loadedForKeys: payload.loadedForKeys,
  }
}

export function fetchSuccess(payload = {}) {
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

export function createRequest() {
  return {
    type: CREATE_REQUEST,
  }
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  }
}

export function createFailure(errors = []) {
  return {
    type: CREATE_FAILURE,
    errors,
  }
}

export function updateRequest() {
  return {
    type: UPDATE_REQUEST,
  }
}

export function updateSuccess() {
  return {
    type: UPDATE_SUCCESS,
  }
}

export function updateFailure(errors = []) {
  return {
    type: UPDATE_FAILURE,
    errors,
  }
}

export function deleteRequest() {
  return {
    type: DELETE_REQUEST,
  }
}

export function deleteSuccess() {
  return {
    type: DELETE_SUCCESS,
  }
}

export function deleteFailure(errors = []) {
  return {
    type: DELETE_FAILURE,
    errors,
  }
}

// Action Creators
export function loadTasks(options) {
  const { entityKey } = options || {}

  return (dispatch, getState) => {
    const loadedForKeys = [...getState().tasks.loadedForKeys]
    if (entityKey && !loadedForKeys.includes(entityKey)) {
      loadedForKeys.push(entityKey)
    }

    dispatch(fetchRequest({ loadedForKeys }))

    const promise = apiV2('/jobs/tasks', options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.TASK_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

export function setTaskValue(taskParams, options = {}) {
  const config = {
    method: 'POST',
    data: taskParams.payload.value,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = apiV2(`/jobs/tasks/${taskParams.payload.id}/value`, options, config)
      .then(({ data }) => {
        const updatedTask = taskParams.task
        updatedTask.value = taskParams.payload.value
        const normalizedJson = normalize(updatedTask, Schemas.TASK_SHEET)

        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function deleteTaskValue(task, options = {}) {
  const config = {
    method: 'DELETE',
  }

  return (dispatch) => {
    dispatch(deleteRequest())

    const promise = apiV2(`/jobs/tasks/${task.id}/value`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(task, Schemas.TASK_SHEET)
        dispatch(deleteEntity(normalizedJson))

        dispatch(deleteSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(deleteFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function sendProviderInvite(id, options = {}) {
  const config = {
    method: 'POST',
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = apiV2(`calendar-events/provider-invites/${id}`, options, config)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.TASK_SHEET)

        dispatch(updateEntities(normalizedJson))
        dispatch(updateSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

export function sendProviderJobList(jobListParams, options = {}) {
  const config = {
    method: 'POST',
    data: jobListParams,
  }

  return (dispatch) => {
    dispatch(updateRequest())

    const promise = apiV2('providers/job-list', options, config)
      .then(({ data }) => {
        dispatch(updateSuccess())

        return { success: true, result: data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(updateFailure(errors))

        return { success: false, errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload, errors } = action

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        loadedForKeys: action.loadedForKeys || state.loadedForKeys,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload.continuationToken,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    case CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      }
    case CREATE_SUCCESS:
      return {
        ...state,
        creating: false,
        errors: [],
      }
    case CREATE_FAILURE:
      return {
        ...state,
        creating: false,
        errors,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        errors: [],
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        errors,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        errors: [],
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        errors,
      }
    default:
      return state
  }
}
