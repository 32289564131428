import general from './general'
import management from './management'
import cms1 from './cms1'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [general, management, cms1],
}

export default menuItems
