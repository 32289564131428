import * as currentUser from './currentUser'
import * as entities from '../entities'
import * as holdSlots from './holdSlot'
import * as merchandises from './merchandise'
import * as offices from './office'
import * as officeProjects from './officeProject'
import * as parts from './part'
import * as prices from './price'
import * as productParts from './productPart'
import * as products from './product'
import * as projects from './project'
import * as projectTemplates from './projectTemplate'
import * as schedules from './schedule'
import * as slots from './slot'
import * as swap from './swap'
import * as tasks from './task'
import * as taskSheets from './taskSheet'
import * as users from './user'
import * as workflows from './workflow'
import * as zones from './zone'
import * as zoneProvidersEstimates from './zoneProvidersEstimate'
import * as zoneSlots from './zoneSlot'

// enums
import * as enums from './enum'

const modules = {
  currentUser,
  entities,
  enums,
  holdSlots,
  merchandises,
  offices,
  officeProjects,
  parts,
  prices,
  productParts,
  products,
  projects,
  projectTemplates,
  schedules,
  slots,
  swap,
  tasks,
  taskSheets,
  users,
  workflows,
  zones,
  zoneProvidersEstimates,
  zoneSlots,
}

export const reducers = Object.keys(modules).reduce((acc, moduleKey) => {
  const module = modules[moduleKey]
  acc[moduleKey] = module.default

  return acc
}, {})

export default modules
