import { normalize } from 'normalizr'
import { Schemas } from 'redux/schema'
import { updateEntities } from 'redux/entities'

import api from 'utils/api'

import { handleError } from '../utils'

const FETCH_REQUEST = 'contenthouse/productPart/FETCH_REQUEST'
const FETCH_SUCCESS = 'contenthouse/productPart/FETCH_SUCCESS'
const FETCH_FAILURE = 'contenthouse/productPart/FETCH_FAILURE'

// Initial State
const initialState = {
  errors: [],
  loaded: false,
  loading: false,
  result: {},
}

// Actions
export function fetchRequest() {
  return {
    type: FETCH_REQUEST,
  }
}

export function fetchSuccess(payload = {}) {
  return {
    type: FETCH_SUCCESS,
    payload,
  }
}

export function fetchFailure(errors = []) {
  return {
    type: FETCH_FAILURE,
    errors,
  }
}

// Action Creators
export function loadProductParts(id, options) {
  return (dispatch) => {
    dispatch(fetchRequest())

    const promise = api(`/products/${id}/parts`, options)
      .then(({ data }) => {
        const normalizedJson = normalize(data, Schemas.PRODUCT_PART_ARRAY)
        dispatch(updateEntities(normalizedJson))
        dispatch(fetchSuccess(data))

        return { success: true, data }
      })
      .catch((data) => {
        const errors = handleError(data)
        dispatch(fetchFailure(errors))

        return { success: false, result: errors }
      })

    return promise
  }
}

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload, errors } = action

  switch (type) {
    case FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        errors: [],
        result: payload,
      }
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        errors,
      }
    default:
      return state
  }
}
