import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import useCurrentUser from 'hooks/useCurrentUser'
import DefaultRoutes from 'routes/DefaultRoutes'
import MainRoutes from 'routes/MainRoutes'
import ProviderRoutes from 'routes/ProviderRoutes'

import PlaceholderPage from 'views/PlaceholderPage'

const AuthorizationController = () => {
  const { currentUser, errors } = useCurrentUser()

  if (errors?.length) {
    return <PlaceholderPage message={errors[0]} />
  }

  if (currentUser) {
    if (!currentUser.roles?.some((x) => ['Back_Office', 'Provider'].includes(x))) {
      return <PlaceholderPage message={'You do not have access to this page.'} />
    }

    if (currentUser.roles?.some((x) => ['Back_Office'].includes(x))) {
      return <RouterProvider router={createBrowserRouter([MainRoutes])} />
    }

    if (currentUser.roles?.some((x) => ['Provider'].includes(x))) {
      return <RouterProvider router={createBrowserRouter([ProviderRoutes])} />
    }

    return <RouterProvider router={createBrowserRouter([DefaultRoutes])} />
  }

  return <PlaceholderPage message={'Loading...'} />
}

export default AuthorizationController
