import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import PortraitIcon from '@mui/icons-material/Portrait'

import config from 'config'
// ==============================|| PROVIDER MENU ITEMS ||============================== //

const providerMenuItems = {
  items: [
    {
      id: 'general',
      title: 'General',
      type: 'group',
      children: [
        {
          id: 'dashboard',
          title: 'Home',
          type: 'item',
          url: '/',
          icon: HomeOutlinedIcon,
          breadcrumbs: false,
        },
        {
          id: 'jobs',
          title: 'Jobs',
          type: 'item',
          url: `${config.cms1Url}/service-jobs`,
          icon: FormatListBulletedOutlinedIcon,
          breadcrumbs: false,
          external: true,
          target: true,
        },
      ],
    },
    {
      id: 'management',
      title: 'Management',
      collapsedTitle: 'Manage',
      type: 'group',
      children: [
        {
          id: 'provider-diary',
          title: 'Provider Diary',
          collapsedTitle: 'Diary',
          type: 'item',
          url: '/provider-diary',
          icon: PortraitIcon,
          breadcrumbs: false,
        },
      ],
    },
  ],
}

export default providerMenuItems
