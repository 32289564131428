import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'

// ==============================|| DEFAULT MENU ITEMS ||============================== //

const defaultMenuItems = {
  items: [
    {
      id: 'general',
      title: 'General',
      type: 'group',
      children: [
        {
          id: 'dashboard',
          title: 'Home',
          type: 'item',
          url: '/',
          icon: HomeOutlinedIcon,
          breadcrumbs: false,
        },
      ],
    },
  ],
}

export default defaultMenuItems
