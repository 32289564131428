import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'
import ErrorElement from 'routes/ErrorElement'

// routing
const Dashboard = Loadable(lazy(() => import('views/Dashboard')))

// ==============================|| DEFAULT ROUTING ||============================== //

const DefaultRoutes = {
  path: '/',
  element: <MainLayout />,
  errorElement: <ErrorElement />,
  children: [
    {
      path: '/',
      element: <Dashboard />,
      errorElement: <ErrorElement />,
    },
  ],
}

export default DefaultRoutes
